// @flow
import { all, call, fork, put, takeEvery} from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    KINDERGARTEN_COMPANIES_GET_SUCCESS,
    COMPANY_WORKERS_GET_SUCCESS,
    COMPANY_WORKERS_GET_FAILED,
    KINDERGARTEN_COMPANIES_GET_FAILED,
    KINDERGARTEN_COMPANIES_GET,
    COMPANY_WORKERS_GET,
    TRAINING_CENTER_WORKERS_GET_FAILED,
    TRAINING_CENTER_WORKERS_GET_SUCCESS,
    TRAINING_CENTER_WORKERS_GET,
    TRAINING_CENTER_COMPANIES_GET_SUCCESS,
    TRAINING_CENTER_COMPANIES_GET_FAILED,
    TRAINING_CENTER_COMPANIES_GET,
    INSURANCE_POLICIES_GET,
    INSURANCE_POLICIES_GET_FAILED,
    INSURANCE_POLICIES_GET_SUCCESS,
    CARD_COMPANY_WORKERS_GET_SUCCESS,
    CARD_COMPANY_WORKERS_GET,
    CARD_COMPANY_WORKERS_GET_FAILED,
    INVOICE_STUDENTS_GET_SUCCESS,
    INVOICE_STUDENTS_GET_FAILED,
    INVOICE_STUDENTS_GET,
    COMPANY_WORKERS_SUPPLIERS_GET_SUCCESS, COMPANY_WORKERS_SUPPLIERS_GET_FAILED, COMPANY_WORKERS_SUPPLIERS_GET
} from './constants';

import i18n from 'i18next';

/**
 * get kindergarten companies
 * @param {*} payload none
 */
function* getKindergartenCompanies({ payload: { id, requested } }) {
    const options = {
        body: JSON.stringify( { id, requested }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const companies = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/company/kindergarten', options);
        yield put({type: KINDERGARTEN_COMPANIES_GET_SUCCESS, data: companies });
    } catch (error) {
        yield put({type: KINDERGARTEN_COMPANIES_GET_FAILED, error: i18n.t(error.message)});
    }
}

/**
 * get company workers
 * @param {*} payload none
 */
function* getCompanyWorkers({ payload: { id, parent, requested, type } }) {
    const options = {
        body: JSON.stringify( { id, parent, requested, type }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const workers = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/user/company', options);
        yield put({type: COMPANY_WORKERS_GET_SUCCESS, data: workers });
    } catch (error) {
        yield put({type: COMPANY_WORKERS_GET_FAILED, error: i18n.t(error.message)});
    }
}

/**
 * get company workers suppliers
 * @param {*} payload none
 */
function* getCompanyWorkersSuppliers({ payload: { values } }) {
    const options = {
        body: JSON.stringify( { values }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const workers = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/supplier/workers', options);
        yield put({type: COMPANY_WORKERS_SUPPLIERS_GET_SUCCESS, data: workers });
    } catch (error) {
        yield put({type: COMPANY_WORKERS_SUPPLIERS_GET_FAILED, error: i18n.t(error.message)});
    }
}

/**
 * get trainingCenter companies
 * @param {*} payload none
 */
function* getTrainingCenterCompanies({ payload: { id, requested } }) {
    const options = {
        body: JSON.stringify( { id, requested }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const companies = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/company/trainingCenter', options);
        yield put({type: TRAINING_CENTER_COMPANIES_GET_SUCCESS, data: companies });
    } catch (error) {
        yield put({type: TRAINING_CENTER_COMPANIES_GET_FAILED, error: i18n.t(error.message)});
    }
}

/**
 * get trainingCenter workers
 * @param {*} payload none
 */
function* getTrainingCenterWorkers({ payload: { id } }) {
    const options = {
        body: JSON.stringify( { id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const companies = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/user/trainingCenter', options);
        yield put({type: TRAINING_CENTER_WORKERS_GET_SUCCESS, data: companies });
    } catch (error) {
        yield put({type: TRAINING_CENTER_WORKERS_GET_FAILED, error: i18n.t(error.message)});
    }
}

/**
 * get insurance policies
 * @param {*} payload none
 */
function* getInsurancePolicies({ payload: { id } }) {
    const options = {
        body: JSON.stringify( { id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const companies = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/modality/insurance/policies/workers', options);
        yield put({type: INSURANCE_POLICIES_GET_SUCCESS, data: companies });
    } catch (error) {
        yield put({type: INSURANCE_POLICIES_GET_FAILED, error: i18n.t(error.message)});
    }
}

/**
 * get card company workers
 * @param {*} payload none
 */
function* getCardCompanyWorkers({ payload: { id } }) {
    const options = {
        body: JSON.stringify( { id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const cardCompanyWorkers = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/user/company/card', options);
        yield put({type: CARD_COMPANY_WORKERS_GET_SUCCESS, data: cardCompanyWorkers });
    } catch (error) {
        yield put({type: CARD_COMPANY_WORKERS_GET_FAILED, error: i18n.t(error.message)});
    }
}

/**
 * get invoice students
 * @param {*} payload none
 */
function* getInvoiceStudents({ payload: { id,type,month,year } }) {
    const options = {
        body: JSON.stringify( { id,type,month,year }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/supplier/invoice/students', options);
        yield put({type: INVOICE_STUDENTS_GET_SUCCESS, data: response });
    } catch (error) {
        yield put({type:INVOICE_STUDENTS_GET_FAILED, error: i18n.t(error.message)});
    }
}

export function* watchInnerRequest(): any {
    yield takeEvery(KINDERGARTEN_COMPANIES_GET, getKindergartenCompanies);
    yield takeEvery(COMPANY_WORKERS_GET, getCompanyWorkers);
    yield takeEvery(COMPANY_WORKERS_SUPPLIERS_GET, getCompanyWorkersSuppliers);
    yield takeEvery(TRAINING_CENTER_COMPANIES_GET, getTrainingCenterCompanies);
    yield takeEvery(TRAINING_CENTER_WORKERS_GET, getTrainingCenterWorkers);
    yield takeEvery(INSURANCE_POLICIES_GET, getInsurancePolicies);
    yield takeEvery(CARD_COMPANY_WORKERS_GET, getCardCompanyWorkers);
    yield takeEvery(INVOICE_STUDENTS_GET, getInvoiceStudents);
}

function* innerSaga(): any {
    yield all([
        fork(watchInnerRequest)
    ]);
}

export default innerSaga;
