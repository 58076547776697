// @flow
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    REQUEST_INSURANCES_FAILED, REQUEST_INSURANCES_SUCCESS, REQUEST_INSURANCES,
    CONFIRM_INSURANCES_FAILED, CONFIRM_INSURANCES_SUCCESS, CONFIRM_INSURANCES
} from './constants';

import i18n from 'i18next';

function* confirmInsurances({ payload: { values, type, callback, params } }) {

    const options = {
        body: JSON.stringify({ values, type }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/demand/insurances/confirm', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: CONFIRM_INSURANCES_FAILED, error: message});
        } else {
            yield put({type: CONFIRM_INSURANCES_SUCCESS, response: response });

            if (callback) {
                yield put({type: callback, payload: params});
            }

        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: CONFIRM_INSURANCES_FAILED, error: message});
    }

}

function* requestInsurances({ payload: { values, type, callback, params } }) {

    const options = {
        body: JSON.stringify({ values, type }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/demand/insurances/request', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: REQUEST_INSURANCES_FAILED, error: message});
        } else {
            yield put({type: REQUEST_INSURANCES_SUCCESS, response: response });

            if (callback) {
                yield put({type: callback, payload: params});
            }

        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
    yield put({type: REQUEST_INSURANCES_FAILED, error: message});
    }

}

export function* watchRequestInsurancesSaga(): any {
    yield takeEvery(REQUEST_INSURANCES, requestInsurances);
}

export function* watchConfirmInsurancesSaga(): any {
    yield takeEvery(CONFIRM_INSURANCES, confirmInsurances);
}

function* InsurancesSaga(): any {
    yield all([
        fork(watchRequestInsurancesSaga),
        fork(watchConfirmInsurancesSaga)
    ]);
}

export default InsurancesSaga;
