/* INVOICES */
export const GENERATE_INVOICES = 'GENERATE_INVOICES';
export const GENERATE_INVOICES_SUCCESS = 'GENERATE_INVOICES_SUCCESS';
export const GENERATE_INVOICES_FAILED = 'GENERATE_INVOICES_FAILED';
export const BANK_VALIDATE_INVOICES = 'BANK_VALIDATE_INVOICES';
export const BANK_VALIDATE_INVOICES_SUCCESS = 'BANK_VALIDATE_INVOICES_SUCCESS';
export const BANK_VALIDATE_INVOICES_FAILED = 'BANK_VALIDATE_INVOICES_FAILED';
export const VALIDATE_INVOICES = 'VALIDATE_INVOICES';
export const VALIDATE_INVOICES_SUCCESS = 'VALIDATE_INVOICES_SUCCESS';
export const VALIDATE_INVOICES_FAILED = 'VALIDATE_INVOICES_FAILED';
export const NOTIFY_INVOICES = 'NOTIFY_INVOICES';
export const NOTIFY_INVOICES_SUCCESS = 'NOTIFY_INVOICES_SUCCESS';
export const NOTIFY_INVOICES_FAILED = 'NOTIFY_INVOICES_FAILED';