// @flow
import {
    REQUEST_INSURANCES, REQUEST_INSURANCES_SUCCESS, REQUEST_INSURANCES_FAILED,
    CONFIRM_INSURANCES, CONFIRM_INSURANCES_SUCCESS, CONFIRM_INSURANCES_FAILED
} from './constants';

const initialState = {
    requestInsurancesData: '',
    requestInsurancesError: '',
    requestInsurancesLoading: false,
    confirmInsurancesData: '',
    confirmInsurancesError: '',
    confirmInsurancesLoading: false,
};

export default function InvoicesReducer(state= initialState, action) {
    switch (action.type) {
        case REQUEST_INSURANCES:
            return { ...state, requestInsurancesLoading: true, requestInsurancesError: null };
        case REQUEST_INSURANCES_SUCCESS:
            return { ...state, requestInsurancesData: action.response, requestInsurancesLoading: false };
        case REQUEST_INSURANCES_FAILED:
            return { ...state, requestInsurancesError: action.error, requestInsurancesLoading: false };
        case CONFIRM_INSURANCES:
            return { ...state, confirmInsurancesLoading: true, confirmInsurancesError: null };
        case CONFIRM_INSURANCES_SUCCESS:
            return { ...state, confirmInsurancesData: action.response, confirmInsurancesLoading: false };
        case CONFIRM_INSURANCES_FAILED:
            return { ...state, confirmInsurancesError: action.error, confirmInsurancesLoading: false };
        default:
            return { ...state };
    }
}