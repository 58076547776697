// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    RESET_COMPANY_CONTRACT,
    UPDATE_COMPANY_CONTRACT,
    REQUEST_COMPANY_SIGNATURE,
    UPDATE_COMPANY_CONTRACT_SUCCESS,
    UPDATE_COMPANY_CONTRACT_FAILED, REQUEST_COMPANY_SIGNATURE_FAILED, REQUEST_COMPANY_SIGNATURE_SUCCESS
} from './constants';

import i18n from 'i18next';
import {takeLast} from "rxjs/operators";

function* resetCompanyContract() {
    yield put({type: RESET_COMPANY_CONTRACT, response: {}});
}

/**
 * updateContract
 * @param { * } payload data
 */
function* updateCompanyContract({ payload: { params } }) {

    const options = {
        body: JSON.stringify({ params }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/company/contract/update', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: UPDATE_COMPANY_CONTRACT_FAILED, error: message});
        } else {
            yield put({type: UPDATE_COMPANY_CONTRACT_SUCCESS, response: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: UPDATE_COMPANY_CONTRACT_FAILED, error: message});
    }

}

/**
 * requestSignature
 */
function* requestCompanySignature() {

    const options = {
        body: JSON.stringify(),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/company/contract/request', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: REQUEST_COMPANY_SIGNATURE_FAILED, error: message});
        } else {
            yield put({type: REQUEST_COMPANY_SIGNATURE_SUCCESS, response: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: REQUEST_COMPANY_SIGNATURE_FAILED, error: message});
    }

}

export function* watchResetCompanyContractSaga(): any {
    yield takeLast(RESET_COMPANY_CONTRACT, resetCompanyContract);
}

export function* watchUpdateCompanyContractSaga(): any {
    yield takeEvery(UPDATE_COMPANY_CONTRACT, updateCompanyContract);
}

export function* watchRequestCompanySignatureSaga(): any {
    yield takeEvery(REQUEST_COMPANY_SIGNATURE, requestCompanySignature);
}

function* CompanySaga(): any {
    yield all([
        fork(watchResetCompanyContractSaga),
        fork(watchUpdateCompanyContractSaga),
        fork(watchRequestCompanySignatureSaga)
    ]);
}

export default CompanySaga;
