// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    COMMON_REQUEST,
    COMMON_SUCCESS,
    COMMON_FAILED,
    PROVINCE_REQUEST,
    PROVINCE_SUCCESS,
    PROVINCE_FAILED,
    CITY_REQUEST,
    CITY_SUCCESS,
    CITY_FAILED,
    COMPANY_REQUEST,
    COMPANY_SUCCESS,
    COMPANY_FAILED,
    AUTONOMY_REQUEST,
    AUTONOMY_SUCCESS,
    AUTONOMY_FAILED,
    FAQ_CATEGORY_SUCCESS,
    FAQ_CATEGORY_FAILED,
    FAQ_CATEGORY_REQUEST,
    MODALITY_CATEGORY_REQUEST,
    MODALITY_CATEGORY_FAILED,
    MODALITY_CATEGORY_SUCCESS,
    ADMIN_HISTORIC_SUCCESS,
    ADMIN_HISTORIC_FAILED,
    ADMIN_HISTORIC_REQUEST,
    COUNTRY_SUCCESS,
    COUNTRY_FAILED,
    COUNTRY_REQUEST,
    CARD_GENERATE,
    CARD_GENERATE_FAILED,
    CARD_GENERATE_SUCCESS,
    CARD_PIN_REQUEST_SUCCESS,
    CARD_PIN_REQUEST_FAILED,
    CARD_TOGGLE_SUCCESS,
    CARD_TOGGLE_FAILED,
    CARD_PIN_REQUEST,
    CARD_TOGGLE,
    CARD_TRANSACTIONS_SUCCESS,
    CARD_TRANSACTIONS_FAILED,
    CARD_TRANSACTIONS,
    CARD_RECHARGE_SUCCESS,
    CARD_RECHARGE_FAILED,
    CARD_RECHARGE,
    CANCEL_SELECTION_SUCCESS,
    CANCEL_SELECTION_FAILED,
    CANCEL_SELECTION_REQUEST,
    CARD_BALANCE_FAILED,
    CARD_BALANCE_SUCCESS,
    CARD_BALANCE,
    CANCEL_INSURANCE_SUCCESS,
    CANCEL_INSURANCE_FAILED,
    CANCEL_INSURANCE_REQUEST,
    CARD_RECHARGE_NOTIFICATION_SUCCESS,
    CARD_RECHARGE_NOTIFICATION_FAILED, CARD_RECHARGE_NOTIFICATION
} from './constants';

import i18n from 'i18next';
import {cardBalance} from "./actions";
import {RESET_SAVINGS} from "../savings/constants";

/**
 * get common
 * @param { * } payload data
 */
function* getCommon({ payload: { path, params } }) {

    const options = {
        body: JSON.stringify({ params }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        if (response.errors) {
            message = response.errors;
        } else {
            yield put({type: COMMON_SUCCESS, data: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
    }
    yield put({type: COMMON_FAILED, error: message});
}

/**
 * get countries
 */
function* getCountries() {
    const options = {
        body: JSON.stringify({  }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const countries = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/common/countries', options);
        yield put({type: COUNTRY_SUCCESS, countries: countries });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: COUNTRY_FAILED, error: message});
    }
}

/**
 * get provinces
 */
function* getProvinces() {
    const options = {
        body: JSON.stringify({  }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const provinces = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/common/provinces', options);
        yield put({type: PROVINCE_SUCCESS, provinces: provinces });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: PROVINCE_FAILED, error: message});
    }
}

/**
 * get cities
 * @param { * } payload province
 */
function* getCities({ payload: { province } }) {

    const options = {
        body: JSON.stringify({ province }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const cities = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/common/cities', options);
        yield put({type: CITY_SUCCESS, cities: cities });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: CITY_FAILED, error: message});
    }
}

/**
 * card generate
 * @param { * } payload user
 */
function* generateCard({ payload: { user } }) {

    const options = {
        body: JSON.stringify({ user }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const card = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/user/card/generate', options);
        if (card.createdAt) {
            yield put({type: CARD_GENERATE_SUCCESS, card: card });
        } else {
            yield put({type: CARD_GENERATE_FAILED, error: card.errors});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: CARD_GENERATE_FAILED, error: message});
    }
}

/**
 * card balance
 * @param { * } payload year, month, isRecharge
 */
function* balanceCard({ payload: { year, month, isRecharge } }) {

    console.log('balanced');

    const options = {
        body: JSON.stringify({ year, month, isRecharge }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const balance = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/dashboard/admin/balance', options);
        if (balance.result === 200) {
            yield put({type: CARD_BALANCE_SUCCESS, cardBalance: balance });
        } else {
            yield put({type: CARD_BALANCE_FAILED, error: balance.errors});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: CARD_BALANCE_FAILED, error: message});
    }
}

/**
 * card pin
 */
function* requestPinCard() {

    const options = {
        body: JSON.stringify(),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const cardPin = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/user/card/pin', options);
        if (cardPin.success) {
            yield put({type: CARD_PIN_REQUEST_SUCCESS, cardPin: cardPin });
        } else {
            yield put({type: CARD_PIN_REQUEST_FAILED, error: cardPin.errors});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: CARD_PIN_REQUEST_FAILED, error: message});
    }
}

/**
 * card toggle
 */
function* toggleCard({ payload: { action } }) {

    const options = {
        body: JSON.stringify({action}),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const cardToggle = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/user/card/toggle', options);
        if (cardToggle.result === 'OK') {
            yield put({type: CARD_TOGGLE_SUCCESS, cardToggle: cardToggle });
        } else {
            yield put({type: CARD_TOGGLE_FAILED, error: cardToggle.errors});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: CARD_TOGGLE_FAILED, error: message});
    }
}

/**
 * card recharge
 */
function* rechargeCard({ payload: { year, month, param } }) {

    const options = {
        body: JSON.stringify({ year, month, param }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const cardRecharge = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/dashboard/admin/recharge', options);
        if (cardRecharge.result === 'OK') {
            let isRecharge = true;
            yield put({type: CARD_BALANCE, payload: {year, month, isRecharge} });
            yield put({type: CARD_RECHARGE_SUCCESS, cardRecharge: cardRecharge });
        } else {
            yield put({type: CARD_RECHARGE_FAILED, error: cardRecharge});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: CARD_RECHARGE_FAILED, error: message});
    }
}


/**
 * card notification
 */
function* rechargeNotification({ payload: { id } }) {

    const options = {
        body: JSON.stringify({ id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const cardRechargeNotification = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/dashboard/admin/recharge/notification', options);
        if (cardRechargeNotification.result === 'OK') {
            yield put({type: CARD_RECHARGE_NOTIFICATION_SUCCESS, cardRechargeNotification: cardRechargeNotification });
        } else {
            yield put({type: CARD_RECHARGE_NOTIFICATION_FAILED, error: cardRechargeNotification});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: CARD_RECHARGE_NOTIFICATION_FAILED, error: message});
    }
}

/**
 * card transactions
 */
function* transactionsCard({ payload: { type } }) {

    const options = {
        body: JSON.stringify({type}),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const cardTransactions = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/user/card/transactions', options);
        if (cardTransactions.success) {
            yield put({type: CARD_TRANSACTIONS_SUCCESS, cardTransactions: cardTransactions });
        } else {
            yield put({type: CARD_TRANSACTIONS_FAILED, error: cardTransactions.errors});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: CARD_TRANSACTIONS_FAILED, error: message});
    }
}

/**
 * get companies
 */
function* getCompanies({ payload: { id } }) {
    const options = {
        body: JSON.stringify({ id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const companies = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/common/companies', options);
        yield put({type: COMPANY_SUCCESS, companies: companies });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: COMPANY_FAILED, error: message});
    }
}

/**
 * get autonomies
 */
function* getAutonomies() {
    const options = {
        body: JSON.stringify({  }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const autonomies = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/common/autonomies', options);
        yield put({type: AUTONOMY_SUCCESS, autonomies: autonomies });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: AUTONOMY_FAILED, error: message});
    }
}

/**
 * get faqCategories
 */
function* getFaqCategories() {
    const options = {
        body: JSON.stringify({  }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const faqCategories = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/common/faqCategories', options);
        yield put({type: FAQ_CATEGORY_SUCCESS, faqCategories: faqCategories });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: FAQ_CATEGORY_FAILED, error: message});
    }
}

/**
 * get modalityCategories
 */
function* getModalityCategories() {
    const options = {
        body: JSON.stringify({  }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const modalityCategories = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/common/modalityCategories', options);
        yield put({type: MODALITY_CATEGORY_SUCCESS, modalityCategories: modalityCategories });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: MODALITY_CATEGORY_FAILED, error: message});
    }
}

/**
 * get adminHistoric
 * @param {*} payload none
 */
function* getAdminHistoric({ payload: { values } }) {
    const options = {
        body: JSON.stringify({ values }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const records = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/dashboard/admin/historic', options);
        yield put({type: ADMIN_HISTORIC_SUCCESS, records: records });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: ADMIN_HISTORIC_FAILED, error: message});
    }
}

/**
 * cancel selection
 * @param {*} values
 */
function* cancelSelection({ payload: { values } }) {
    const options = {
        body: JSON.stringify({ values }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const result = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/selections/cancel', options);
        yield put({type: CANCEL_SELECTION_SUCCESS, result: result});
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: CANCEL_SELECTION_FAILED, error: message});
    }
}

/**
 * cancel insurance
 * @param {*} values
 */
function* cancelInsurance({ payload: { values } }) {
    const options = {
        body: JSON.stringify({ values }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const result = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/selections/insurance/cancel', options);
        yield put({type: CANCEL_INSURANCE_SUCCESS, result: result});
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: CANCEL_INSURANCE_FAILED, error: message});
    }
}

export function* watchCommonSaga(): any {
    yield takeEvery(COMMON_REQUEST, getCommon);
}

export function* watchCountrySaga(): any {
    yield takeEvery(COUNTRY_REQUEST, getCountries);
}

export function* watchProvinceSaga(): any {
    yield takeEvery(PROVINCE_REQUEST, getProvinces);
}

export function* watchCitySaga(): any {
    yield takeEvery(CITY_REQUEST, getCities);
}

export function* watchCompanySaga(): any {
    yield takeEvery(COMPANY_REQUEST, getCompanies);
}

export function* watchAutonomySaga(): any {
    yield takeEvery(AUTONOMY_REQUEST, getAutonomies);
}

export function* watchFaqCategorySaga(): any {
    yield takeEvery(FAQ_CATEGORY_REQUEST, getFaqCategories);
}

export function* watchModalityCategorySaga(): any {
    yield takeEvery(MODALITY_CATEGORY_REQUEST, getModalityCategories);
}

export function* watchAdminHistoric(): any {
    yield takeEvery(ADMIN_HISTORIC_REQUEST, getAdminHistoric);
}

export function* watchCancelSelection(): any {
    yield takeEvery(CANCEL_SELECTION_REQUEST, cancelSelection);
}

export function* watchCancelInsurance(): any {
    yield takeEvery(CANCEL_INSURANCE_REQUEST, cancelInsurance);
}

export function* watchCardSaga(): any {
    yield takeEvery(CARD_GENERATE, generateCard);
    yield takeEvery(CARD_PIN_REQUEST, requestPinCard);
    yield takeEvery(CARD_TOGGLE, toggleCard);
    yield takeEvery(CARD_TRANSACTIONS, transactionsCard);
    yield takeEvery(CARD_RECHARGE, rechargeCard);
    yield takeEvery(CARD_RECHARGE_NOTIFICATION, rechargeNotification);
    yield takeEvery(CARD_BALANCE, balanceCard);
}

function* commonSaga(): any {
    yield all([
        fork(watchCommonSaga),
        fork(watchCountrySaga),
        fork(watchProvinceSaga),
        fork(watchCitySaga),
        fork(watchCardSaga),
        fork(watchCompanySaga),
        fork(watchAutonomySaga),
        fork(watchFaqCategorySaga),
        fork(watchModalityCategorySaga),
        fork(watchAdminHistoric),
        fork(watchCancelSelection),
        fork(watchCancelInsurance)
    ]);
}

export default commonSaga;
