// @flow
import {
    INTEGRATE_PAYROLLS, NOTIFY_PAYROLLS, VALIDATE_PAYROLLS
} from './constants';

type PayrollsAction = { type: string, payload: {} | string };

export const validatePayrollsSaga = (values, type, callback, params): PayrollsAction => ({
    type: VALIDATE_PAYROLLS,
    payload: {values, type, callback, params},
});

export const integratePayrollsSaga = (values, type, callback, params): PayrollsAction => ({
    type: INTEGRATE_PAYROLLS,
    payload: {values, type, callback, params},
});

export const notifyPayrollsSaga = (values, callback, params): PayrollsAction => ({
    type: NOTIFY_PAYROLLS,
    payload: {values, callback, params},
});