// @flow
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    GENERATE_INVOICES_FAILED,
    GENERATE_INVOICES_SUCCESS,
    GENERATE_INVOICES,
    VALIDATE_INVOICES_FAILED,
    VALIDATE_INVOICES_SUCCESS,
    NOTIFY_INVOICES_FAILED,
    NOTIFY_INVOICES_SUCCESS,
    VALIDATE_INVOICES,
    NOTIFY_INVOICES,
    BANK_VALIDATE_INVOICES_FAILED, BANK_VALIDATE_INVOICES_SUCCESS, BANK_VALIDATE_INVOICES
} from './constants';

import i18n from 'i18next';

function* generateInvoices({ payload: { filters, callback, params } }) {

    const options = {
        body: JSON.stringify({ filters }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/invoice/generate', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: GENERATE_INVOICES_FAILED, error: message});
        } else {
            yield put({type: GENERATE_INVOICES_SUCCESS, response: response });

            if (callback) {
                yield put({type: callback, payload: params});
            }

        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: GENERATE_INVOICES_FAILED, error: message});
    }

}

function* validateInvoices({ payload: { values, type, callback, params } }) {

    const options = {
        body: JSON.stringify({ values, type }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/invoice/validate', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: VALIDATE_INVOICES_FAILED, error: message});
        } else {
            yield put({type: VALIDATE_INVOICES_SUCCESS, response: response });

            if (callback) {
                yield put({type: callback, payload: params});
            }

        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: VALIDATE_INVOICES_FAILED, error: message});
    }

}

function* bankValidateInvoices({ payload: { values, type, callback, params } }) {

    const options = {
        body: JSON.stringify({ values, type }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/invoice/bankValidate', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: BANK_VALIDATE_INVOICES_FAILED, error: message});
        } else {
            yield put({type: BANK_VALIDATE_INVOICES_SUCCESS, response: response });

            if (callback) {
                yield put({type: callback, payload: params});
            }

        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: BANK_VALIDATE_INVOICES_FAILED, error: message});
    }

}

function* notifyInvoices({ payload: { values, callback, params } }) {

    const options = {
        body: JSON.stringify({ values }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/invoice/notify', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: NOTIFY_INVOICES_FAILED, error: message});
        } else {
            yield put({type: NOTIFY_INVOICES_SUCCESS, response: response });

            if (callback) {
                yield put({type: callback, payload: params});
            }

        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: NOTIFY_INVOICES_FAILED, error: message});
    }

}

export function* watchGenerateInvoicesSaga(): any {
    yield takeEvery(GENERATE_INVOICES, generateInvoices);
}

export function* watchBankValidateInvoicesSaga(): any {
    yield takeEvery(BANK_VALIDATE_INVOICES, bankValidateInvoices);
}

export function* watchValidateInvoicesSaga(): any {
    yield takeEvery(VALIDATE_INVOICES, validateInvoices);
}

export function* watchNotifyInvoicesSaga(): any {
    yield takeEvery(NOTIFY_INVOICES, notifyInvoices);
}

function* InvoicesSaga(): any {
    yield all([
        fork(watchGenerateInvoicesSaga),
        fork(watchValidateInvoicesSaga),
        fork(watchBankValidateInvoicesSaga),
        fork(watchNotifyInvoicesSaga),
    ]);
}

export default InvoicesSaga;
