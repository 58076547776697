// @flow
import {
    REQUEST_INSURANCES,
    CONFIRM_INSURANCES
} from './constants';

type InsurancesAction = { type: string, payload: {} | string };

export const requestInsurancesSaga = (values, type, callback, params): InsurancesAction => ({
    type: REQUEST_INSURANCES,
    payload: {values, type, callback, params},
});

export const confirmInsurancesSaga = (values, type, callback, params): InsurancesAction => ({
    type: CONFIRM_INSURANCES,
    payload: {values, type, callback, params},
});