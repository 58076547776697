// @flow
import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Common from "./common/reducers";
import Entities from "./api/reducers";
import Inner from "./inner/reducers";
import Savings from "./savings/reducers";
import Invoices from "./invoices/reducers";
import Insurances from "./insurances/reducers";
import Payrolls from "./payrolls/reducers";
import Suppliers from "./suppliers/reducers";
import Companies from "./companies/reducers";

export default combineReducers({
    Auth,
    AppMenu,
    Layout,
    Common,
    Entities,
    Inner,
    Savings,
    Invoices,
    Insurances,
    Payrolls,
    Suppliers,
    Companies
});
