export const KINDERGARTEN_COMPANIES_GET = 'KINDERGARTEN_COMPANIES_GET';
export const KINDERGARTEN_COMPANIES_GET_SUCCESS = 'KINDERGARTEN_COMPANIES_GET_SUCCESS';
export const KINDERGARTEN_COMPANIES_GET_FAILED = 'KINDERGARTEN_COMPANIES_GET_FAILED';

export const COMPANY_WORKERS_GET = 'COMPANY_WORKERS_GET';
export const COMPANY_WORKERS_GET_SUCCESS = 'COMPANY_WORKERS_GET_SUCCESS';
export const COMPANY_WORKERS_GET_FAILED = 'COMPANY_WORKERS_GET_FAILED';

export const COMPANY_WORKERS_SUPPLIERS_GET = 'COMPANY_WORKERS_SUPPLIERS_GET';
export const COMPANY_WORKERS_SUPPLIERS_GET_SUCCESS = 'COMPANY_WORKERS_SUPPLIERS_GET_SUCCESS';
export const COMPANY_WORKERS_SUPPLIERS_GET_FAILED = 'COMPANY_WORKERS_SUPPLIERS_GET_FAILED';

export const TRAINING_CENTER_COMPANIES_GET = 'TRAINING_CENTER_COMPANIES_GET';
export const TRAINING_CENTER_COMPANIES_GET_SUCCESS = 'TRAINING_CENTER_COMPANIES_GET_SUCCESS';
export const TRAINING_CENTER_COMPANIES_GET_FAILED = 'TRAINING_CENTER_COMPANIES_GET_FAILED';

export const TRAINING_CENTER_WORKERS_GET = 'TRAINING_CENTER_WORKERS_GET';
export const TRAINING_CENTER_WORKERS_GET_SUCCESS = 'TRAINING_CENTER_WORKERS_GET_SUCCESS';
export const TRAINING_CENTER_WORKERS_GET_FAILED = 'TRAINING_CENTER_WORKERS_GET_FAILED';

export const INSURANCE_POLICIES_GET = 'INSURANCE_POLICIES_GET';
export const INSURANCE_POLICIES_GET_SUCCESS = 'INSURANCE_POLICIES_GET_SUCCESS';
export const INSURANCE_POLICIES_GET_FAILED = 'INSURANCE_POLICIES_GET_FAILED';

export const CARD_COMPANY_WORKERS_GET = 'CARD_COMPANY_WORKERS_GET';
export const CARD_COMPANY_WORKERS_GET_SUCCESS = 'CARD_COMPANY_WORKERS_GET_SUCCESS';
export const CARD_COMPANY_WORKERS_GET_FAILED = 'CARD_COMPANY_WORKERS_GET_FAILED';

export const INVOICE_STUDENTS_GET = 'INVOICE_STUDENTS_GET';
export const INVOICE_STUDENTS_GET_SUCCESS = 'INVOICE_STUDENTS_GET_SUCCESS';
export const INVOICE_STUDENTS_GET_FAILED = 'INVOICE_STUDENTS_GET_FAILED';