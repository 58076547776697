// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    RESET_SUPPLIER_CONTRACT,
    UPDATE_SUPPLIER_CONTRACT,
    REQUEST_SUPPLIER_SIGNATURE,
    UPDATE_SUPPLIER_CONTRACT_SUCCESS,
    UPDATE_SUPPLIER_CONTRACT_FAILED, REQUEST_SUPPLIER_SIGNATURE_FAILED, REQUEST_SUPPLIER_SIGNATURE_SUCCESS
} from './constants';

import i18n from 'i18next';
import {takeLast} from "rxjs/operators";

function* resetSupplierContract() {
    yield put({type: RESET_SUPPLIER_CONTRACT, response: {}});
}

/**
 * updateContract
 * @param { * } payload data
 */
function* updateSupplierContract({ payload: { params } }) {

    const options = {
        body: JSON.stringify({ params }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/supplier/contract/update', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: UPDATE_SUPPLIER_CONTRACT_FAILED, error: message});
        } else {
            yield put({type: UPDATE_SUPPLIER_CONTRACT_SUCCESS, response: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: UPDATE_SUPPLIER_CONTRACT_FAILED, error: message});
    }

}

/**
 * requestSignature
 */
function* requestSupplierSignature() {

    const options = {
        body: JSON.stringify(),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/supplier/contract/request', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: REQUEST_SUPPLIER_SIGNATURE_FAILED, error: message});
        } else {
            yield put({type: REQUEST_SUPPLIER_SIGNATURE_SUCCESS, response: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: REQUEST_SUPPLIER_SIGNATURE_FAILED, error: message});
    }

}

export function* watchResetSupplierContractSaga(): any {
    yield takeLast(RESET_SUPPLIER_CONTRACT, resetSupplierContract);
}

export function* watchUpdateSupplierContractSaga(): any {
    yield takeEvery(UPDATE_SUPPLIER_CONTRACT, updateSupplierContract);
}

export function* watchRequestSupplierSignatureSaga(): any {
    yield takeEvery(REQUEST_SUPPLIER_SIGNATURE, requestSupplierSignature);
}

function* SuppliersSaga(): any {
    yield all([
        fork(watchResetSupplierContractSaga),
        fork(watchUpdateSupplierContractSaga),
        fork(watchRequestSupplierSignatureSaga)
    ]);
}

export default SuppliersSaga;
