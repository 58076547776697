// @flow
import {
    REQUEST_SIGNATURE,
    UPDATE_CONTRACT,
    REMIND_SIGNATURE, REQUEST_KINDERGARTEN, GET_CHILDREN, GET_KINDERGARTEN_SELECTION,
    RESET_CONTRACT, RESET_SAVINGS, GET_BENEFICIARIES
} from './constants';

type SavingsAction = { type: string, payload: {} | string };

export const resetContract = (): SavingsAction => ({
    type: RESET_CONTRACT,
    payload: {},
});

export const resetSavings = (): SavingsAction => ({
    type: RESET_SAVINGS,
    payload: {},
});

export const updateContract = (modality, params, reset): SavingsAction => ({
    type: UPDATE_CONTRACT,
    payload: {modality, params, reset},
});

export const requestSignature = (): SavingsAction => ({
    type: REQUEST_SIGNATURE,
    payload: {},
});

export const remindSignature = (contract): SavingsAction => ({
    type: REMIND_SIGNATURE,
    payload: {contract},
});

export const requestKindergarten = (values): SavingsAction => ({
    type: REQUEST_KINDERGARTEN,
    payload: {values},
});

export const getChildren = (): SavingsAction => ({
    type: GET_CHILDREN,
    payload: {},
});

export const getKindergartenSelection = (kindergarten, child): SavingsAction => ({
    type: GET_KINDERGARTEN_SELECTION,
    payload: {kindergarten, child},
});

export const getBeneficiaries = (id): SavingsAction => ({
    type: GET_BENEFICIARIES,
    payload: {id},
});