// @flow
import {
    BANK_VALIDATE_INVOICES,
    GENERATE_INVOICES, NOTIFY_INVOICES, VALIDATE_INVOICES
} from './constants';

type InvoicesAction = { type: string, payload: {} | string };

export const generateInvoicesSaga = (filters, callback, params): InvoicesAction => ({
    type: GENERATE_INVOICES,
    payload: {filters, callback, params},
});

export const bankValidateInvoicesSaga = (values, type, callback, params): InvoicesAction => ({
    type: BANK_VALIDATE_INVOICES,
    payload: {values, type, callback, params},
});

export const validateInvoicesSaga = (values, type, callback, params): InvoicesAction => ({
    type: VALIDATE_INVOICES,
    payload: {values, type, callback, params},
});

export const notifyInvoicesSaga = (values, callback, params): InvoicesAction => ({
    type: NOTIFY_INVOICES,
    payload: {values, callback, params},
});