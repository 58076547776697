// @flow
import {
    COMPANY_WORKERS_GET,
    KINDERGARTEN_COMPANIES_GET,
    TRAINING_CENTER_WORKERS_GET,
    COMPANY_WORKERS_SUPPLIERS_GET,
    TRAINING_CENTER_COMPANIES_GET,
    INSURANCE_POLICIES_GET,
    CARD_COMPANY_WORKERS_GET,
    INVOICE_STUDENTS_GET
} from './constants';

type InnerAction = { type: string, payload: {} | string };

export const getCompanyWorkersSaga = (id, parent, requested, type): InnerAction => ({
    type: COMPANY_WORKERS_GET,
    payload: {id, parent, requested, type},
});

export const getKindergartenCompaniesSaga = (id, requested): InnerAction => ({
    type: KINDERGARTEN_COMPANIES_GET,
    payload: {id, requested},
});

export const getTrainingCenterCompaniesSaga = (id, requested): InnerAction => ({
    type: TRAINING_CENTER_COMPANIES_GET,
    payload: {id, requested},
});

export const getCompanyWorkersSuppliersSaga = (values): InnerAction => ({
    type: COMPANY_WORKERS_SUPPLIERS_GET,
    payload: {values},
});

export const getTrainingCenterWorkersSaga = (id): InnerAction => ({
    type: TRAINING_CENTER_WORKERS_GET,
    payload: {id},
});

export const getInsurancePoliciesSaga = (id): InnerAction => ({
    type: INSURANCE_POLICIES_GET,
    payload: {id},
});

export const getCardCompanyWorkersSaga = (id): InnerAction => ({
    type: CARD_COMPANY_WORKERS_GET,
    payload: {id},
});

export const getInvoiceStudentsSaga = (id,type,month,year): InnerAction => ({
    type: INVOICE_STUDENTS_GET,
    payload: {id,type,month,year},
});