// @flow
import {
    UPDATE_COMPANY_CONTRACT,
    UPDATE_COMPANY_CONTRACT_SUCCESS,
    UPDATE_COMPANY_CONTRACT_FAILED,
    REQUEST_COMPANY_SIGNATURE_SUCCESS,
    REQUEST_COMPANY_SIGNATURE,
    REQUEST_COMPANY_SIGNATURE_FAILED,
    RESET_COMPANY_CONTRACT
} from './constants';

const initialState = {
    companyContractLoading: false,
    companyContractError: '',
    companyContractData: {},
    requestCompanySignatureSending: false,
    requestCompanySignatureError: '',
    requestCompanySignatureData: {}
};

export default function CompaniesReducer(state= initialState, action) {
    switch (action.type) {
        case RESET_COMPANY_CONTRACT:
            return { ...state, companyContractData: action.response, companyContractLoading: false, companyContractError: null };

        case UPDATE_COMPANY_CONTRACT:
            return { ...state, companyContractLoading: true, companyContractError: null };
        case UPDATE_COMPANY_CONTRACT_SUCCESS:
            return { ...state, companyContractData: action.response, companyContractLoading: false };
        case UPDATE_COMPANY_CONTRACT_FAILED:
            return { ...state, companyContractError: action.error, companyContractLoading: false };

        case REQUEST_COMPANY_SIGNATURE:
            return { ...state, requestCompanySignatureSending: true, requestCompanySignatureError: null };
        case REQUEST_COMPANY_SIGNATURE_SUCCESS:
            return { ...state, requestCompanySignatureData: action.response, requestCompanySignatureSending: false };
        case REQUEST_COMPANY_SIGNATURE_FAILED:
            return { ...state, requestCompanySignatureError: action.error, requestCompanySignatureSending: false };
        default:
            return { ...state };
    }
}