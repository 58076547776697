// @flow
import { all, call, fork, put, takeEvery} from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    ENTITIES_REQUEST, ENTITIES_SUCCESS, ENTITIES_FAILED,
    ENTITY_ADD, ENTITY_ADD_SUCCESS, ENTITY_ADD_FAILED,
    ENTITY_GET, ENTITY_GET_SUCCESS, ENTITY_GET_FAILED,
    ENTITY_UPDATE, ENTITY_UPDATE_SUCCESS, ENTITY_UPDATE_FAILED,
    ENTITY_DELETE, ENTITY_DELETE_FAILED,
    MODAL_ENTITIES_REQUEST, MODAL_ENTITIES_SUCCESS, MODAL_ENTITIES_FAILED,
    MODAL_ENTITY_ADD, MODAL_ENTITY_ADD_SUCCESS, MODAL_ENTITY_ADD_FAILED,
    MODAL_ENTITY_GET, MODAL_ENTITY_GET_SUCCESS, MODAL_ENTITY_GET_FAILED,
    MODAL_ENTITY_UPDATE, MODAL_ENTITY_UPDATE_SUCCESS, MODAL_ENTITY_UPDATE_FAILED,
    MODAL_ENTITY_DELETE, MODAL_ENTITY_DELETE_FAILED,
    MODAL_ENTITY_RESET, MODAL_ENTITY_RESET_SUCCESS, ENTITY_RESET_SUCCESS, ENTITY_RESET
} from './constants';

import i18n from 'i18next';

/**
 * get entities
 * @param {*} payload none
 */
function* getEntities({ payload: { path, params } }) {
    const options = {
        body: JSON.stringify( { params }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const entities = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        yield put({type: ENTITIES_SUCCESS, data: entities });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: ENTITIES_FAILED, error: message});
    }
}

/**
 * add entity
 * @param {*} payload values
 */
function* addEntity({ payload: { path, values, history, forwarding } }) {
    const options = {
        body: JSON.stringify({ values }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message;

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        if (response.id) {
            yield put({type: ENTITY_ADD_SUCCESS, entity: response });
            if (history) {
                yield call(() => {
                    history.push(forwarding);
                });
            } else {
                yield put({type: forwarding});
            }
        } else {
            message = response.errors;
            yield put({type: ENTITY_ADD_FAILED, error: message});
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: ENTITY_ADD_FAILED, error: message});
    }
}

/**
 * get entity
 * @param {*} payload none
 */
function* getEntity({ payload: { path, id, callback } }) {

    const options = {
        body: JSON.stringify({ id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        yield put({type: ENTITY_GET_SUCCESS, entity: response });
        if (callback) {
            yield put({type: callback, payload: response });
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: ENTITY_GET_FAILED, error: message});
    }
}

/**
 * update entity
 * @param {*} payload values
 */
function* updateEntity({ payload: { path, values, id, history, forwarding } }) {
    const options = {
        body: JSON.stringify({ values, id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        if (response.id) {
            yield put({type: ENTITY_UPDATE_SUCCESS, entity: response });
            if (forwarding) {
                yield call(() => {
                    history.push(forwarding);
                });
            }
        } else {
            yield put({type: ENTITY_UPDATE_FAILED, payload: i18n.t(response.errors)});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: ENTITY_UPDATE_FAILED, payload: i18n.t(message)});
    }
}

/**
 * delete entity
 * @param {*} payload {}
 */
function* deleteEntity({ payload: { path, id, onClose, forwarding } }) {

    const options = {
        body: JSON.stringify({ id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        if (response) {
            yield put({type: ENTITIES_REQUEST, payload: { path: forwarding } });
            onClose(true);
        } else {
            yield put({type: ENTITY_DELETE_FAILED, error: ''});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: ENTITY_DELETE_FAILED, error: message});
    }
}

/**
 * get modal entities
 * @param {*} payload path
 */
function* getModalEntities({ payload: { path, parent } }) {
    const options = {
        body: JSON.stringify({ parent }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const modalEntities = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        yield put({type: MODAL_ENTITIES_SUCCESS, data: modalEntities });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: MODAL_ENTITIES_FAILED, error: message});
    }
}

/**
 * add modal entity
 * @param {*} payload values
 */
function* addModalEntity({ payload: { path, values, forwarding, parent, parentPayload } }) {
    const options = {
        body: JSON.stringify({ values }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message;
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        if (response.id) {
            yield put({type: MODAL_ENTITY_ADD_SUCCESS, modalEntity: {} });
            if (forwarding) {
                yield put({type: MODAL_ENTITIES_REQUEST, payload: {path: forwarding, parent: parent}});
            } else {
                yield put({type: parent, payload: parentPayload});
            }
        } else {
            message = response.message;
            yield put({type: MODAL_ENTITY_ADD_FAILED, error: message});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: MODAL_ENTITY_ADD_FAILED, error: message});
    }
}

/**
 * get modal entity
 * @param {*} payload none
 */
function* getModalEntity({ payload: { path, id } }) {

    const options = {
        body: JSON.stringify({ id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        yield put({type: MODAL_ENTITY_GET_SUCCESS, modalEntity: response });
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: MODAL_ENTITY_GET_FAILED, error: message});
    }
}

/**
 * update modal entity
 * @param path
 * @param values
 * @param id
 * @param forwarding
 * @param parent
 * @param type
 * @param {*} payload values
 */
function* updateModalEntity({ payload: { path, values, id, forwarding, parent, type, params } }) {
    const options = {
        body: JSON.stringify({ values, id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        if (response.id || response.status === 200) {

            yield put({type: MODAL_ENTITY_UPDATE_SUCCESS, modalEntity: response });

            if (forwarding) {
                yield put({type: MODAL_ENTITIES_REQUEST, payload: {path: forwarding, parent: parent}});
            }

            if (type) {
                yield put({type: type, payload: params});
            }

        } else {
            yield put({type: MODAL_ENTITY_UPDATE_FAILED, payload: i18n.t(response.errors)});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: MODAL_ENTITY_UPDATE_FAILED, payload: i18n.t(message)});
    }
}

/**
 * delete modal entity
 * @param {*} payload {}
 */
function* deleteModalEntity({ payload: { path, id, onClose, forwarding, parent } }) {

    const options = {
        body: JSON.stringify({ id }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + path, options);
        if (response) {
            yield put({type: MODAL_ENTITIES_REQUEST, payload: { path: forwarding, parent: parent } });
            onClose(true);
        } else {
            yield put({type: MODAL_ENTITY_DELETE_FAILED, error: ''});
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: MODAL_ENTITY_DELETE_FAILED, error: message});
    }
}

/**
 * reset entity
 */
function* resetEntity() {
    try {
        yield put({type: ENTITY_RESET_SUCCESS, entity: {} });
    } catch (error) {}
}

/**
 * reset modal entity
 */
function* resetModalEntity() {
    try {
        yield put({type: MODAL_ENTITY_RESET_SUCCESS, modalEntity: {} });
    } catch (error) {}
}

export function* watchEntitiesRequest(): any {
    yield takeEvery(ENTITIES_REQUEST, getEntities);
}

export function* watchEntityAdd(): any {
    yield takeEvery(ENTITY_ADD, addEntity);
}

export function* watchEntityGet(): any {
    yield takeEvery(ENTITY_GET, getEntity);
}

export function* watchEntityUpdate(): any {
    yield takeEvery(ENTITY_UPDATE, updateEntity);
}

export function* watchEntityDelete(): any {
    yield takeEvery(ENTITY_DELETE, deleteEntity);
}

export function* watchModalEntitiesRequest(): any {
    yield takeEvery(MODAL_ENTITIES_REQUEST, getModalEntities);
}

export function* watchModalEntityAdd(): any {
    yield takeEvery(MODAL_ENTITY_ADD, addModalEntity);
}

export function* watchModalEntityGet(): any {
    yield takeEvery(MODAL_ENTITY_GET, getModalEntity);
}

export function* watchModalEntityUpdate(): any {
    yield takeEvery(MODAL_ENTITY_UPDATE, updateModalEntity);
}

export function* watchModalEntityDelete(): any {
    yield takeEvery(MODAL_ENTITY_DELETE, deleteModalEntity);
}

export function* watchModalEntityReset(): any {
    yield takeEvery(MODAL_ENTITY_RESET, resetModalEntity);
}

export function* watchEntityReset(): any {
    yield takeEvery(ENTITY_RESET, resetEntity);
}

function* entitySaga(): any {
    yield all([
        fork(watchEntitiesRequest),
        fork(watchEntityAdd),
        fork(watchEntityGet),
        fork(watchEntityUpdate),
        fork(watchEntityDelete),
        fork(watchModalEntitiesRequest),
        fork(watchModalEntityAdd),
        fork(watchModalEntityGet),
        fork(watchModalEntityReset),
        fork(watchModalEntityDelete),
        fork(watchModalEntityUpdate),
        fork(watchEntityReset)
    ]);
}

export default entitySaga;
