// @flow
import {
    KINDERGARTEN_COMPANIES_GET,
    KINDERGARTEN_COMPANIES_GET_SUCCESS,
    COMPANY_WORKERS_GET_FAILED,
    KINDERGARTEN_COMPANIES_GET_FAILED,
    COMPANY_WORKERS_GET,
    COMPANY_WORKERS_GET_SUCCESS,
    TRAINING_CENTER_WORKERS_GET,
    TRAINING_CENTER_WORKERS_GET_SUCCESS,
    TRAINING_CENTER_WORKERS_GET_FAILED,
    TRAINING_CENTER_COMPANIES_GET,
    TRAINING_CENTER_COMPANIES_GET_SUCCESS,
    TRAINING_CENTER_COMPANIES_GET_FAILED,
    INSURANCE_POLICIES_GET,
    INSURANCE_POLICIES_GET_SUCCESS,
    INSURANCE_POLICIES_GET_FAILED,
    CARD_COMPANY_WORKERS_GET,
    CARD_COMPANY_WORKERS_GET_SUCCESS,
    CARD_COMPANY_WORKERS_GET_FAILED,
    INVOICE_STUDENTS_GET,
    INVOICE_STUDENTS_GET_SUCCESS,
    INVOICE_STUDENTS_GET_FAILED,
    COMPANY_WORKERS_SUPPLIERS_GET,
    COMPANY_WORKERS_SUPPLIERS_GET_SUCCESS, COMPANY_WORKERS_SUPPLIERS_GET_FAILED
} from './constants';

const initialState = {
    companies: {},
    companiesError: '',
    companiesLoading: false,
    workers: {},
    workersError: '',
    workersLoading: false,
    trainingCompanies: {},
    trainingCompaniesError: '',
    trainingCompaniesLoading: false,
    trainingWorkers: {},
    trainingWorkersError: '',
    trainingWorkersLoading: false,
    insurancePolicies: {},
    insurancePoliciesError: '',
    insurancePoliciesLoading: false,
    cardCompanyWorkers: {},
    cardCompanyWorkersError: '',
    cardCompanyWorkersLoading: false,
    students: {},
    studentsError: '',
    studentsLoading: false,
};

export default function InnerReducer(state= initialState, action) {
    switch (action.type) {

        case KINDERGARTEN_COMPANIES_GET:
            return { ...state, companiesLoading: true, companiesError: null };
        case KINDERGARTEN_COMPANIES_GET_SUCCESS:
            return { ...state, companies: action.data, companiesLoading: false };
        case KINDERGARTEN_COMPANIES_GET_FAILED:
            return { ...state, companiesError: action.error, companiesLoading: false };

        case COMPANY_WORKERS_GET:
            return { ...state, workersLoading: true, workersError: null };
        case COMPANY_WORKERS_GET_SUCCESS:
            return { ...state, workers: action.data, workersLoading: false };
        case COMPANY_WORKERS_GET_FAILED:
            return { ...state, workersError: action.error, workersLoading: false };

        case COMPANY_WORKERS_SUPPLIERS_GET:
            return { ...state, workersLoading: true, workersError: null };
        case COMPANY_WORKERS_SUPPLIERS_GET_SUCCESS:
            return { ...state, workers: action.data, workersLoading: false };
        case COMPANY_WORKERS_SUPPLIERS_GET_FAILED:
            return { ...state, workersError: action.error, workersLoading: false };

        case TRAINING_CENTER_COMPANIES_GET:
            return { ...state, companiesLoading: true, companiesError: null };
        case TRAINING_CENTER_COMPANIES_GET_SUCCESS:
            return { ...state, companies: action.data, companiesLoading: false };
        case TRAINING_CENTER_COMPANIES_GET_FAILED:
            return { ...state, companiesError: action.error, companiesLoading: false };

        case TRAINING_CENTER_WORKERS_GET:
            return { ...state, trainingWorkersLoading: true, trainingWorkersError: null };
        case TRAINING_CENTER_WORKERS_GET_SUCCESS:
            return { ...state, trainingWorkers: action.data, trainingWorkersLoading: false };
        case TRAINING_CENTER_WORKERS_GET_FAILED:
            return { ...state, trainingWorkersError: action.error, trainingWorkersLoading: false };

        case INSURANCE_POLICIES_GET:
            return { ...state, insurancePoliciesLoading: true, insurancePoliciesError: null };
        case INSURANCE_POLICIES_GET_SUCCESS:
            return { ...state, insurancePolicies: action.data, insurancePoliciesLoading: false };
        case INSURANCE_POLICIES_GET_FAILED:
            return { ...state, insurancePoliciesError: action.error, insurancePoliciesLoading: false };

        case CARD_COMPANY_WORKERS_GET:
            return { ...state, cardCompanyWorkersLoading: true, cardCompanyWorkersError: null };
        case CARD_COMPANY_WORKERS_GET_SUCCESS:
            return { ...state, cardCompanyWorkers: action.data, cardCompanyWorkersLoading: false };
        case CARD_COMPANY_WORKERS_GET_FAILED:
            return { ...state, cardCompanyWorkersError: action.error, cardCompanyWorkersLoading: false };

        case INVOICE_STUDENTS_GET:
            return { ...state, studentsLoading: true, studentsError: null };
        case INVOICE_STUDENTS_GET_SUCCESS:
            return { ...state, students: action.data, studentsLoading: false };
        case INVOICE_STUDENTS_GET_FAILED:
            return { ...state, studentsError: action.error, studentsLoading: false };

        default:
            return { ...state };
    }
}