// @flow
import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import appMenuSaga from './appMenu/saga';
import commonSaga from "./common/saga";
import EntitySaga from "./api/saga";
import InnerSaga from "./inner/saga";
import SavingsSaga from "./savings/saga";
import SuppliersSaga from "./suppliers/saga";
import CompanySaga from "./companies/saga";
import InvoicesSaga from "./invoices/saga";
import PayrollsSaga from "./payrolls/saga";
import InsurancesSaga from "./insurances/saga";

export default function* rootSaga(getState: any): any {
    yield all([
        authSaga(),
        layoutSaga(),
        appMenuSaga(),
        commonSaga(),
        EntitySaga(),
        InnerSaga(),
        SavingsSaga(),
        InvoicesSaga(),
        InsurancesSaga(),
        PayrollsSaga(),
        SuppliersSaga(),
        CompanySaga()
    ]);
}
