// @flow
import {
    REQUEST_COMPANY_SIGNATURE,
    UPDATE_COMPANY_CONTRACT,
    RESET_COMPANY_CONTRACT
} from './constants';

type CompanyAction = { type: string, payload: {} | string };

export const resetCompanyContract = (): CompanyAction => ({
    type: RESET_COMPANY_CONTRACT,
    payload: {},
});

export const updateCompanyContract = (modality, params): CompanyAction => ({
    type: UPDATE_COMPANY_CONTRACT,
    payload: {modality, params},
});

export const requestCompanySignature = (): CompanyAction => ({
    type: REQUEST_COMPANY_SIGNATURE,
    payload: {},
});