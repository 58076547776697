// @flow
import {
    UPDATE_CONTRACT,
    UPDATE_CONTRACT_SUCCESS,
    UPDATE_CONTRACT_FAILED,
    REQUEST_SIGNATURE_SUCCESS,
    REQUEST_SIGNATURE,
    REQUEST_SIGNATURE_FAILED,
    REMIND_SIGNATURE_SUCCESS,
    REMIND_SIGNATURE,
    REMIND_SIGNATURE_FAILED,
    REQUEST_KINDERGARTEN,
    REQUEST_KINDERGARTEN_SUCCESS,
    REQUEST_KINDERGARTEN_FAILED,
    GET_CHILDREN,
    GET_CHILDREN_SUCCESS,
    GET_CHILDREN_FAILED,
    GET_KINDERGARTEN_SELECTION,
    GET_KINDERGARTEN_SELECTION_SUCCESS,
    GET_KINDERGARTEN_SELECTION_FAILED,
    RESET_CONTRACT,
    RESET_SAVINGS, GET_BENEFICIARIES, GET_BENEFICIARIES_SUCCESS, GET_BENEFICIARIES_FAILED
} from './constants';

const initialState = {
    savingsLoading: false,
    savingsError: '',
    savingsData: {},
    requestSignatureSending: false,
    requestSignatureError: '',
    requestSignatureData: {},
    remindSignatureSending: false,
    remindSignatureError: '',
    remindSignatureData: {},
    requestKindergartenSending: false,
    requestKindergartenError: '',
    requestKindergartenSuccess: false,
    requestKindergartenData: {},
    childrenData: '',
    childrenError: '',
    childrenSuccess: '',
    childrenLoading: false,
    beneficiariesData: '',
    beneficiariesError: '',
    beneficiariesSuccess: '',
    beneficiariesLoading: false,
    getKindergartenSelectionLoading: false,
    getKindergartenSelectionError: '',
    getKindergartenSelectionData: '',
};

export default function SavingsReducer(state= initialState, action) {
    switch (action.type) {
        case RESET_SAVINGS:
            return { ...state, savingsData: action.response, savingsLoading: false, savingsError: null };
        case RESET_CONTRACT:
            return { ...state, savingsData: action.response, savingsLoading: false, savingsError: null };
        case UPDATE_CONTRACT:
            return { ...state, savingsLoading: true, savingsError: null };
        case UPDATE_CONTRACT_SUCCESS:
            return { ...state, savingsData: action.response, savingsLoading: false };
        case UPDATE_CONTRACT_FAILED:
            return { ...state, savingsError: action.error, savingsLoading: false };
        case REQUEST_SIGNATURE:
            return { ...state, requestSignatureSending: true, requestSignatureError: null };
        case REQUEST_SIGNATURE_SUCCESS:
            return { ...state, requestSignatureData: action.response, requestSignatureSending: false };
        case REQUEST_SIGNATURE_FAILED:
            return { ...state, requestSignatureError: action.error, requestSignatureSending: false };
        case REMIND_SIGNATURE:
            return { ...state, remindSignatureSending: true, remindSignatureError: null };
        case REMIND_SIGNATURE_SUCCESS:
            return { ...state, remindSignatureData: action.response, remindSignatureSending: false };
        case REMIND_SIGNATURE_FAILED:
            return { ...state, remindSignatureError: action.error, remindSignatureSending: false };
        case REQUEST_KINDERGARTEN:
            return { ...state, requestKindergartenSending: true, requestKindergartenSuccess: false, requestKindergartenError: null };
        case REQUEST_KINDERGARTEN_SUCCESS:
            return { ...state, requestKindergartenData: action.response, requestKindergartenSuccess: true, requestKindergartenSending: false };
        case REQUEST_KINDERGARTEN_FAILED:
            return { ...state, requestKindergartenError: action.error, requestKindergartenSuccess: false, requestKindergartenSending: false };
        case GET_CHILDREN:
            return { ...state, childrenLoading: true, childrenSuccess: false, childrenError: null };
        case GET_CHILDREN_SUCCESS:
            return { ...state, childrenData: action.response, childrenSuccess: true, childrenLoading: false };
        case GET_CHILDREN_FAILED:
            return { ...state, childrenError: action.error, childrenSuccess: false, childrenLoading: false };
        case GET_BENEFICIARIES:
            return { ...state, beneficiariesLoading: true, beneficiariesSuccess: false, beneficiariesError: null };
        case GET_BENEFICIARIES_SUCCESS:
            return { ...state, beneficiariesData: action.response, beneficiariesSuccess: true, beneficiariesLoading: false };
        case GET_BENEFICIARIES_FAILED:
            return { ...state, beneficiariesError: action.error, beneficiariesSuccess: false, beneficiariesLoading: false };
        case GET_KINDERGARTEN_SELECTION:
            return { ...state, getKindergartenSelectionLoading: true, getKindergartenSelectionError: null };
        case GET_KINDERGARTEN_SELECTION_SUCCESS:
            return { ...state, getKindergartenSelectionData: action.response, getKindergartenSelectionLoading: false };
        case GET_KINDERGARTEN_SELECTION_FAILED:
            return { ...state, getKindergartenSelectionError: action.error, getKindergartenSelectionLoading: false };
        default:
            return { ...state };
    }
}