/* COMMON */
export const COMMON_REQUEST = 'COMMON_REQUEST';
export const COMMON_SUCCESS = 'COMMON_SUCCESS';
export const COMMON_FAILED = 'COMMON_FAILED';
export const COUNTRY_REQUEST = 'COUNTRY_REQUEST';
export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
export const COUNTRY_FAILED = 'COUNTRY_FAILED';
export const PROVINCE_REQUEST = 'PROVINCE_REQUEST';
export const PROVINCE_SUCCESS = 'PROVINCE_SUCCESS';
export const PROVINCE_FAILED = 'PROVINCE_FAILED';
export const CITY_REQUEST = 'CITY_REQUEST';
export const CITY_SUCCESS = 'CITY_SUCCESS';
export const CITY_FAILED = 'CITY_FAILED';
export const CARD_GENERATE = 'CARD_GENERATE';
export const CARD_GENERATE_SUCCESS = 'CARD_GENERATE_SUCCESS';
export const CARD_GENERATE_FAILED = 'CARD_GENERATE_FAILED';
export const COMPANY_REQUEST = 'COMPANY_REQUEST';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const COMPANY_FAILED = 'COMPANY_FAILED';
export const AUTONOMY_REQUEST = 'AUTONOMY_REQUEST';
export const AUTONOMY_SUCCESS = 'AUTONOMY_SUCCESS';
export const AUTONOMY_FAILED = 'AUTONOMY_FAILED';
export const FAQ_CATEGORY_REQUEST = 'FAQ_CATEGORY_REQUEST';
export const FAQ_CATEGORY_SUCCESS = 'FAQ_CATEGORY_SUCCESS';
export const FAQ_CATEGORY_FAILED = 'FAQ_CATEGORY_FAILED';
export const MODALITY_CATEGORY_REQUEST = 'MODALITY_CATEGORY_REQUEST';
export const MODALITY_CATEGORY_SUCCESS = 'MODALITY_CATEGORY_SUCCESS';
export const MODALITY_CATEGORY_FAILED = 'MODALITY_CATEGORY_FAILED';
export const ADMIN_HISTORIC_REQUEST = 'ADMIN_HISTORIC_REQUEST';
export const ADMIN_HISTORIC_SUCCESS = 'ADMIN_HISTORIC_SUCCESS';
export const ADMIN_HISTORIC_FAILED = 'ADMIN_HISTORIC_FAILED';

export const CARD_PIN_REQUEST = 'CARD_PIN_REQUEST';
export const CARD_PIN_REQUEST_SUCCESS = 'CARD_PIN_REQUEST_SUCCESS';
export const CARD_PIN_REQUEST_FAILED = 'CARD_PIN_REQUEST_FAILED';
export const CARD_TOGGLE = 'CARD_TOGGLE';
export const CARD_TOGGLE_SUCCESS = 'CARD_TOGGLE_SUCCESS';
export const CARD_TOGGLE_FAILED = 'CARD_TOGGLE_FAILED';
export const CARD_TRANSACTIONS = 'CARD_TRANSACTIONS';
export const CARD_TRANSACTIONS_SUCCESS = 'CARD_TRANSACTIONS_SUCCESS';
export const CARD_TRANSACTIONS_FAILED = 'CARD_TRANSACTIONS_FAILED';
export const CARD_RECHARGE = 'CARD_RECHARGE';
export const CARD_RECHARGE_SUCCESS = 'CARD_RECHARGE_SUCCESS';
export const CARD_RECHARGE_FAILED = 'CARD_RECHARGE_FAILED';
export const CARD_RECHARGE_NOTIFICATION = 'CARD_RECHARGE_NOTIFICATION';
export const CARD_RECHARGE_NOTIFICATION_SUCCESS = 'CARD_RECHARGE_NOTIFICATION_SUCCESS';
export const CARD_RECHARGE_NOTIFICATION_FAILED = 'CARD_RECHARGE_NOTIFICATION_FAILED';
export const CARD_BALANCE = 'CARD_BALANCE';
export const CARD_BALANCE_SUCCESS = 'CARD_BALANCE_SUCCESS';
export const CARD_BALANCE_FAILED = 'CARD_BALANCE_FAILED';

export const CANCEL_SELECTION_REQUEST = 'CANCEL_SELECTION_REQUEST';
export const CANCEL_SELECTION_SUCCESS = 'CANCEL_SELECTION_SUCCESS';
export const CANCEL_SELECTION_FAILED = 'CANCEL_SELECTION_FAILED';

export const CANCEL_INSURANCE_REQUEST = 'CANCEL_INSURANCE_REQUEST';
export const CANCEL_INSURANCE_SUCCESS = 'CANCEL_INSURANCE_SUCCESS';
export const CANCEL_INSURANCE_FAILED = 'CANCEL_INSURANCE_FAILED';



