// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';

import {
    UPDATE_CONTRACT,
    UPDATE_CONTRACT_SUCCESS,
    UPDATE_CONTRACT_FAILED,
    REQUEST_SIGNATURE_SUCCESS,
    REQUEST_SIGNATURE_FAILED,
    REQUEST_SIGNATURE,
    REMIND_SIGNATURE_SUCCESS,
    REMIND_SIGNATURE_FAILED,
    REMIND_SIGNATURE,
    REQUEST_KINDERGARTEN_SUCCESS,
    REQUEST_KINDERGARTEN_FAILED,
    REQUEST_KINDERGARTEN,
    GET_CHILDREN_SUCCESS,
    GET_CHILDREN_FAILED,
    GET_CHILDREN,
    GET_KINDERGARTEN_SELECTION_FAILED, GET_KINDERGARTEN_SELECTION_SUCCESS, GET_KINDERGARTEN_SELECTION,
    RESET_CONTRACT, RESET_SAVINGS, GET_BENEFICIARIES_FAILED, GET_BENEFICIARIES_SUCCESS, GET_BENEFICIARIES
} from './constants';

import i18n from 'i18next';
import {takeLast} from "rxjs/operators";

function* resetSavings() {
    yield put({type: RESET_SAVINGS, response: {}});
}

function* resetContract() {
    yield put({type: RESET_CONTRACT, response: {}});
}

/**
 * updateContract
 * @param { * } payload data
 */
function* updateContract({ payload: { modality, params, reset } }) {

    const options = {
        body: JSON.stringify({ modality, params }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/savings/update', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: UPDATE_CONTRACT_FAILED, error: message});
        } else {
            yield put({type: UPDATE_CONTRACT_SUCCESS, response: response });
            if (reset) {
                yield put({type: RESET_SAVINGS, response: {} });
            }
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: UPDATE_CONTRACT_FAILED, error: message});
    }

}

/**
 * requestSignature
 */
function* requestSignature() {

    const options = {
        body: JSON.stringify(),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/savings/signing/request', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: REQUEST_SIGNATURE_FAILED, error: message});
        } else {
            yield put({type: REQUEST_SIGNATURE_SUCCESS, response: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: REQUEST_SIGNATURE_FAILED, error: message});
    }

}

/**
 * remindSignature
 * @param { * } payload data
 */
function* remindSignature({ payload: { contract } }) {

    const options = {
        body: JSON.stringify({ contract }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/savings/signing/remind', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: REMIND_SIGNATURE_FAILED, error: message});
        } else {
            yield put({type: REMIND_SIGNATURE_SUCCESS, response: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: REMIND_SIGNATURE_FAILED, error: message});
    }

}

/**
 * requestKindergarten
 * @param { * } payload data
 */
function* requestKindergarten({ payload: { values } }) {

    const options = {
        body: JSON.stringify({ values }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/savings/kindergarten/request', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: REQUEST_KINDERGARTEN_FAILED, error: message});
        } else {
            yield put({type: REQUEST_KINDERGARTEN_SUCCESS, response: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: REQUEST_KINDERGARTEN_FAILED, error: message});
    }

}

/**
 * getChildren
 */
function* getChildren() {

    const options = {
        body: JSON.stringify(),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/savings/kindergarten/children', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: GET_CHILDREN_FAILED, error: message });
        } else {
            yield put({type: GET_CHILDREN_SUCCESS, response: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: GET_CHILDREN_FAILED, error: message});
    }

}

/**
 * getBeneficiaries
 */
function* getBeneficiaries({ payload: { id } }) {

    const options = {
        body: JSON.stringify({id}),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/savings/insurance/beneficiaries', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: GET_BENEFICIARIES_FAILED, error: message });
        } else {
            yield put({type: GET_BENEFICIARIES_SUCCESS, response: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: GET_BENEFICIARIES_FAILED, error: message});
    }

}

/**
 * getKindergartenSelection
 * @param { * } payload data
 */
function* getKindergartenSelection({ payload: { kindergarten, child } }) {

    const options = {
        body: JSON.stringify({ kindergarten, child }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    let message = '';
    try {
        const response = yield call(fetchJSON, process.env.REACT_APP_BACKEND + '/savings/kindergarten/selection', options);
        if (response.errors) {
            message = response.errors;
            yield put({type: GET_KINDERGARTEN_SELECTION_FAILED, error: message });
        } else {
            yield put({type: GET_KINDERGARTEN_SELECTION_SUCCESS, response: response });
        }
    } catch (error) {
        switch (error.status) {
            case 500:
                message = i18n.t('auth.errors.500');
                break;
            case 401:
                message = i18n.t('auth.errors.401');
                break;
            default:
                message = error;
        }
        yield put({type: GET_KINDERGARTEN_SELECTION_FAILED, error: message});
    }

}

export function* watchResetContractSaga(): any {
    yield takeLast(RESET_CONTRACT, resetContract);
}

export function* watchResetSavingsSaga(): any {
    yield takeLast(RESET_SAVINGS, resetSavings);
}

export function* watchUpdateContractSaga(): any {
    yield takeEvery(UPDATE_CONTRACT, updateContract);
}

export function* watchRequestSignatureSaga(): any {
    yield takeEvery(REQUEST_SIGNATURE, requestSignature);
}

export function* watchRemindSignatureSaga(): any {
    yield takeEvery(REMIND_SIGNATURE, remindSignature);
}

export function* watchRequestKindergartenSaga(): any {
    yield takeEvery(REQUEST_KINDERGARTEN, requestKindergarten);
}

export function* watchGetChildrenSaga(): any {
    yield takeEvery(GET_CHILDREN, getChildren);
}

export function* watchGetBeneficiariesSaga(): any {
    yield takeEvery(GET_BENEFICIARIES, getBeneficiaries);
}

export function* watchGetKindergartenSelection(): any {
    yield takeEvery(GET_KINDERGARTEN_SELECTION, getKindergartenSelection);
}

function* SavingsSaga(): any {
    yield all([
        fork(watchResetSavingsSaga),
        fork(watchResetContractSaga),
        fork(watchUpdateContractSaga),
        fork(watchRequestSignatureSaga),
        fork(watchRemindSignatureSaga),
        fork(watchRequestKindergartenSaga),
        fork(watchGetChildrenSaga),
        fork(watchGetKindergartenSelection),
        fork(watchGetBeneficiariesSaga)
    ]);
}

export default SavingsSaga;
