/* SAVINGS */
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS';
export const UPDATE_CONTRACT_FAILED = 'UPDATE_CONTRACT_FAILED';
export const REQUEST_SIGNATURE = 'REQUEST_SIGNATURE';
export const REQUEST_SIGNATURE_SUCCESS = 'REQUEST_SIGNATURE_SUCCESS';
export const REQUEST_SIGNATURE_FAILED = 'REQUEST_SIGNATURE_FAILED';
export const REMIND_SIGNATURE = 'REMIND_SIGNATURE';
export const REMIND_SIGNATURE_SUCCESS = 'REMIND_SIGNATURE_SUCCESS';
export const REMIND_SIGNATURE_FAILED = 'REMIND_SIGNATURE_FAILED';
export const REQUEST_KINDERGARTEN = 'REQUEST_KINDERGARTEN';
export const REQUEST_KINDERGARTEN_SUCCESS = 'REQUEST_KINDERGARTEN_SUCCESS';
export const REQUEST_KINDERGARTEN_FAILED = 'REQUEST_KINDERGARTEN_FAILED';
export const GET_CHILDREN = 'GET_CHILDREN';
export const GET_CHILDREN_SUCCESS = 'GET_CHILDREN_SUCCESS';
export const GET_CHILDREN_FAILED = 'GET_CHILDREN_FAILED';
export const GET_BENEFICIARIES = 'GET_BENEFICIARIES';
export const GET_BENEFICIARIES_SUCCESS = 'GET_BENEFICIARIES_SUCCESS';
export const GET_BENEFICIARIES_FAILED = 'GET_BENEFICIARIES_FAILED';
export const GET_KINDERGARTEN_SELECTION = 'GET_KINDERGARTEN_SELECTION';
export const GET_KINDERGARTEN_SELECTION_SUCCESS = 'GET_KINDERGARTEN_SELECTION_SUCCESS';
export const GET_KINDERGARTEN_SELECTION_FAILED = 'GET_KINDERGARTEN_SELECTION_FAILED';
export const RESET_CONTRACT = 'RESET_CONTRACT';
export const RESET_SAVINGS = 'RESET_SAVINGS';



