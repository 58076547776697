// @flow
import {
    REQUEST_SUPPLIER_SIGNATURE,
    UPDATE_SUPPLIER_CONTRACT,
    RESET_SUPPLIER_CONTRACT
} from './constants';

type SuppliersAction = { type: string, payload: {} | string };

export const resetSupplierContract = (): SuppliersAction => ({
    type: RESET_SUPPLIER_CONTRACT,
    payload: {},
});

export const updateSupplierContract = (modality, params): SuppliersAction => ({
    type: UPDATE_SUPPLIER_CONTRACT,
    payload: {modality, params},
});

export const requestSupplierSignature = (): SuppliersAction => ({
    type: REQUEST_SUPPLIER_SIGNATURE,
    payload: {},
});