// @flow
import {
    NOTIFY_PAYROLLS, NOTIFY_PAYROLLS_FAILED, NOTIFY_PAYROLLS_SUCCESS,
    VALIDATE_PAYROLLS, VALIDATE_PAYROLLS_FAILED, VALIDATE_PAYROLLS_SUCCESS,
    INTEGRATE_PAYROLLS, INTEGRATE_PAYROLLS_FAILED, INTEGRATE_PAYROLLS_SUCCESS
} from './constants';

const initialState = {
    validatePayrollsData: '',
    validatePayrollsError: '',
    validatePayrollsLoading: false,
    integratePayrollsData: '',
    integratePayrollsError: '',
    integratePayrollsLoading: false,
    notifyPayrollsData: '',
    notifyPayrollsError: '',
    notifyPayrollsLoading: false,
};

export default function PayrollsReducer(state= initialState, action) {
    switch (action.type) {
        case VALIDATE_PAYROLLS:
            return { ...state, validatePayrollsLoading: true, validatePayrollsError: null };
        case VALIDATE_PAYROLLS_SUCCESS:
            return { ...state, validatePayrollsData: action.response, validatePayrollsLoading: false };
        case VALIDATE_PAYROLLS_FAILED:
            return { ...state, validatePayrollsError: action.error, validatePayrollsLoading: false };
        case INTEGRATE_PAYROLLS:
            return { ...state, integratePayrollsLoading: true, integratePayrollsError: null };
        case INTEGRATE_PAYROLLS_SUCCESS:
            return { ...state, integratePayrollsData: action.response, integratePayrollsLoading: false };
        case INTEGRATE_PAYROLLS_FAILED:
            return { ...state, integratePayrollsError: action.error, integratePayrollsLoading: false };
        case NOTIFY_PAYROLLS:
            return { ...state, notifyPayrollsLoading: true, generatePayrollsError: null };
        case NOTIFY_PAYROLLS_SUCCESS:
            return { ...state, notifyPayrollsData: action.response, notifyPayrollsLoading: false };
        case NOTIFY_PAYROLLS_FAILED:
            return { ...state, notifyPayrollsError: action.error, notifyPayrollsLoading: false };
        default:
            return { ...state };
    }
}