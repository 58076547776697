// @flow
import {
    UPDATE_SUPPLIER_CONTRACT,
    UPDATE_SUPPLIER_CONTRACT_SUCCESS,
    UPDATE_SUPPLIER_CONTRACT_FAILED,
    REQUEST_SUPPLIER_SIGNATURE_SUCCESS,
    REQUEST_SUPPLIER_SIGNATURE,
    REQUEST_SUPPLIER_SIGNATURE_FAILED,
    RESET_SUPPLIER_CONTRACT
} from './constants';

const initialState = {
    supplierContractLoading: false,
    supplierContractError: '',
    supplierContractData: {},
    requestSupplierSignatureSending: false,
    requestSupplierSignatureError: '',
    requestSupplierSignatureData: {}
};

export default function SavingsReducer(state= initialState, action) {
    switch (action.type) {
        case RESET_SUPPLIER_CONTRACT:
            return { ...state, supplierContractData: action.response, supplierContractLoading: false, supplierContractError: null };

        case UPDATE_SUPPLIER_CONTRACT:
            return { ...state, supplierContractLoading: true, supplierContractError: null };
        case UPDATE_SUPPLIER_CONTRACT_SUCCESS:
            return { ...state, supplierContractData: action.response, supplierContractLoading: false };
        case UPDATE_SUPPLIER_CONTRACT_FAILED:
            return { ...state, supplierContractError: action.error, supplierContractLoading: false };

        case REQUEST_SUPPLIER_SIGNATURE:
            return { ...state, requestSupplierSignatureSending: true, requestSupplierSignatureError: null };
        case REQUEST_SUPPLIER_SIGNATURE_SUCCESS:
            return { ...state, requestSupplierSignatureData: action.response, requestSupplierSignatureSending: false };
        case REQUEST_SUPPLIER_SIGNATURE_FAILED:
            return { ...state, requestSupplierSignatureError: action.error, requestSupplierSignatureSending: false };
        default:
            return { ...state };
    }
}