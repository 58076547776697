// @flow
import React, {Component} from 'react';

import {withTranslation} from "react-i18next";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {getLoggedInUser} from "../../../helpers/authUtils";
class CompanyContractChild extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedInUser: getLoggedInUser()
        };
    }

    render() {

        const {t} = this.props;

        return (
            <React.Fragment>

                <Row className="mt-3">

                    <Col>
                        <Card>
                            <CardHeader className={'bg-tandem'}>
                                <h5 className={'mt-0 mb-0 text-primary'}>{t('app.dashboard.organization.children.contract.title')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={12}>
                                        {t('app.dashboard.organization.children.contract.description')} <b>{this.state.loggedInUser.parent.name}</b>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </React.Fragment>


        );
    };
}

export default withTranslation()(CompanyContractChild);
