// @flow
import {
    GENERATE_INVOICES, GENERATE_INVOICES_FAILED, GENERATE_INVOICES_SUCCESS,
    NOTIFY_INVOICES, NOTIFY_INVOICES_FAILED, NOTIFY_INVOICES_SUCCESS,
    VALIDATE_INVOICES, VALIDATE_INVOICES_FAILED, VALIDATE_INVOICES_SUCCESS,
    BANK_VALIDATE_INVOICES, BANK_VALIDATE_INVOICES_FAILED, BANK_VALIDATE_INVOICES_SUCCESS
} from './constants';

const initialState = {
    generateInvoicesData: '',
    generateInvoicesError: '',
    generateInvoicesLoading: false,
    bankValidateInvoicesData: '',
    bankValidateInvoicesError: '',
    bankValidateInvoicesLoading: false,
    validateInvoicesData: '',
    validateInvoicesError: '',
    validateInvoicesLoading: false,
    notifyInvoicesData: '',
    notifyInvoicesError: '',
    notifyInvoicesLoading: false,
};

export default function InvoicesReducer(state= initialState, action) {
    switch (action.type) {
        case GENERATE_INVOICES:
            return { ...state, generateInvoicesLoading: true, generateInvoicesError: null };
        case GENERATE_INVOICES_SUCCESS:
            return { ...state, generateInvoicesData: action.response, generateInvoicesLoading: false };
        case GENERATE_INVOICES_FAILED:
            return { ...state, generateInvoicesError: action.error, generateInvoicesLoading: false };
        case BANK_VALIDATE_INVOICES:
            return { ...state, bankValidateInvoicesLoading: true, bankValidateInvoicesError: null };
        case BANK_VALIDATE_INVOICES_SUCCESS:
            return { ...state, bankValidateInvoicesData: action.response, bankValidateInvoicesLoading: false };
        case BANK_VALIDATE_INVOICES_FAILED:
            return { ...state, bankValidateInvoicesError: action.error, bankValidateInvoicesLoading: false };
        case VALIDATE_INVOICES:
            return { ...state, validateInvoicesLoading: true, validateInvoicesError: null };
        case VALIDATE_INVOICES_SUCCESS:
            return { ...state, validateInvoicesData: action.response, validateInvoicesLoading: false, validateInvoicesError: null };
        case VALIDATE_INVOICES_FAILED:
            return { ...state, validateInvoicesError: action.error, validateInvoicesLoading: false };
        case NOTIFY_INVOICES:
            return { ...state, notifyInvoicesLoading: true, notifyInvoicesError: null };
        case NOTIFY_INVOICES_SUCCESS:
            return { ...state, notifyInvoicesData: action.response, notifyInvoicesLoading: false, notifyInvoicesError: null };
        case NOTIFY_INVOICES_FAILED:
            return { ...state, notifyInvoicesError: action.error, notifyInvoicesLoading: false };
        default:
            return { ...state };
    }
}